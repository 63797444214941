// name is different than our usual convention cause the root api file is called EntityApi
import {createRequestHook, ObjectSchema} from 'react-enty';
import {RpcRequest} from '~/core/data/Endpoints';
import {RpcType} from '~/core/data/EntityApi';
import {getEntityListSchema, getEntityItemSchema, getPublisherAndMediaOwnerSchema} from './Schemas';

export type Entity =
    | AdType
    | Brand
    | Campaign
    | Category
    | Industry
    | MediaOwner
    | MediaType
    | Product
    | Publication
    | Publisher
    | Region;
export type EntityType = Exclude<
    NonNullable<RpcType['searchQuery']['payload']['type']>,
    'creative' | 'rule'
>;

export type AdType = RpcType['adTypeGet']['response'];
export type Brand = RpcType['brandGet']['response'];
export type Campaign = RpcType['campaignGet']['response'];
export type Category = RpcType['categoryGet']['response'];
export type Industry = RpcType['industryGet']['response'];
export type MediaOwner = RpcType['mediaOwnerGet']['response'];
export type MediaType = RpcType['mediaTypeGet']['response'];
export type Product = RpcType['productGet']['response'];
export type Publication = RpcType['publicationGet']['response'];
export type Publisher = RpcType['publisherGet']['response'];
export type Region = RpcType['regionGet']['response'];

export const entitySearch = createRequestHook({
    name: 'entitySearch',
    schema: getEntityListSchema(),
    request: RpcRequest('searchQuery')
});

type EntityListInput =
    | {payload: RpcType['adTypeList']['payload']; type: 'adType'}
    | {payload: RpcType['brandList']['payload']; type: 'brand'}
    | {payload: RpcType['campaignList']['payload']; type: 'campaign'}
    | {payload: RpcType['categoryList']['payload']; type: 'category'}
    | {payload: RpcType['industryList']['payload']; type: 'industry'}
    | {payload: RpcType['mediaOwnerList']['payload']; type: 'mediaOwner'}
    | {payload: RpcType['mediaTypeList']['payload']; type: 'mediaType'}
    | {payload: RpcType['productList']['payload']; type: 'product'}
    | {payload: RpcType['publicationList']['payload']; type: 'publication'}
    | {payload: RpcType['publisherList']['payload']; type: 'publisher'}
    | {payload: RpcType['regionList']['payload']; type: 'region'};
export const entityList = createRequestHook({
    name: 'entityList',
    schema: getEntityListSchema(),
    request: async (input: EntityListInput, meta) => {
        const {type, payload} = input;

        switch (type) {
            case 'adType':
                return {adType: await RpcRequest('adTypeList')(payload, meta)};
            case 'brand':
                return {brand: await RpcRequest('brandList')(payload, meta)};
            case 'campaign':
                return {campaign: await RpcRequest('campaignList')(payload, meta)};
            case 'category':
                return {category: await RpcRequest('categoryList')(payload, meta)};
            case 'industry':
                return {industry: await RpcRequest('industryList')(payload, meta)};
            case 'mediaOwner':
                return {mediaOwner: await RpcRequest('mediaOwnerList')(payload, meta)};
            case 'mediaType':
                return {mediaType: await RpcRequest('mediaTypeList')(payload, meta)};
            case 'product':
                return {product: await RpcRequest('productList')(payload, meta)};
            case 'publication':
                return {publication: await RpcRequest('publicationList')(payload, meta)};
            case 'publisher':
                return {publisher: await RpcRequest('publisherList')(payload, meta)};
            case 'region':
                return {region: await RpcRequest('regionList')(payload, meta)};
            default:
                throw new Error(`get list ${type} was not handled`);
        }
    }
});

export const entityItem = createRequestHook({
    name: 'entityItem',
    schema: getEntityItemSchema(),
    request: async (payload: {id: string; includeArchived?: boolean; type: EntityType}, meta) => {
        const {id, type, includeArchived = true} = payload;

        switch (type) {
            case 'adType':
                return {adType: await RpcRequest('adTypeGet')({id, includeArchived}, meta)};
            case 'brand':
                return {brand: await RpcRequest('brandGet')({id, includeArchived}, meta)};
            case 'campaign':
                return {
                    campaign: await RpcRequest('campaignGet')(
                        {id: BigInt(id), includeArchived},
                        meta
                    )
                };
            case 'category':
                return {category: await RpcRequest('categoryGet')({id, includeArchived}, meta)};
            case 'industry':
                return {industry: await RpcRequest('industryGet')({id, includeArchived}, meta)};
            case 'mediaOwner':
                return {mediaOwner: await RpcRequest('mediaOwnerGet')({id, includeArchived}, meta)};
            case 'mediaType':
                return {mediaType: await RpcRequest('mediaTypeGet')({id, includeArchived}, meta)};
            case 'product':
                return {product: await RpcRequest('productGet')({id, includeArchived}, meta)};
            case 'publisher':
                return {publisher: await RpcRequest('publisherGet')({id, includeArchived}, meta)};
            case 'publication':
                return {
                    publication: await RpcRequest('publicationGet')(
                        {id: BigInt(id), includeArchived},
                        meta
                    )
                };
            case 'region':
                return {region: await RpcRequest('regionGet')({id, includeArchived}, meta)};
            default:
                throw new Error(`get ${type} was not handled`);
        }
    }
});

type EntityUpdateInput =
    | {payload: RpcType['adTypeUpdate']['payload']; type: 'adType'}
    | {payload: RpcType['brandUpdate']['payload']; type: 'brand'}
    | {payload: RpcType['campaignUpdate']['payload']; type: 'campaign'}
    | {payload: RpcType['categoryUpdate']['payload']; type: 'category'}
    | {payload: RpcType['industryUpdate']['payload']; type: 'industry'}
    | {payload: RpcType['mediaOwnerUpdate']['payload']; type: 'mediaOwner'}
    | {payload: RpcType['mediaTypeUpdate']['payload']; type: 'mediaType'}
    | {payload: RpcType['productUpdate']['payload']; type: 'product'}
    | {payload: RpcType['publicationUpdate']['payload']; type: 'publication'}
    | {payload: RpcType['publisherUpdate']['payload']; type: 'publisher'}
    | {payload: RpcType['regionUpdate']['payload']; type: 'region'};

export const entityUpdate = createRequestHook({
    name: 'entityUpdate',
    schema: getEntityItemSchema(),
    request: async (input: EntityUpdateInput, meta) => {
        switch (input.type) {
            case 'adType':
                return {adType: await RpcRequest('adTypeUpdate')(input.payload, meta)};
            case 'brand':
                return {brand: await RpcRequest('brandUpdate')(input.payload, meta)};
            case 'campaign':
                return {campaign: await RpcRequest('campaignUpdate')(input.payload, meta)};
            case 'category':
                return {category: await RpcRequest('categoryUpdate')(input.payload, meta)};
            case 'industry':
                return {industry: await RpcRequest('industryUpdate')(input.payload, meta)};
            case 'mediaOwner':
                return {mediaOwner: await RpcRequest('mediaOwnerUpdate')(input.payload, meta)};
            case 'mediaType':
                return {mediaType: await RpcRequest('mediaTypeUpdate')(input.payload, meta)};
            case 'product':
                return {product: await RpcRequest('productUpdate')(input.payload, meta)};
            case 'publication':
                return {publication: await RpcRequest('publicationUpdate')(input.payload, meta)};
            case 'publisher':
                return {publisher: await RpcRequest('publisherUpdate')(input.payload, meta)};
            case 'region':
                return {region: await RpcRequest('regionUpdate')(input.payload, meta)};
            default:
                throw new Error(`update was not handled`);
        }
    }
});

type EntityCreateInput =
    | {payload: RpcType['adTypeCreate']['payload']; type: 'adType'}
    | {payload: RpcType['brandCreate']['payload']; type: 'brand'}
    | {payload: RpcType['campaignCreate']['payload']; type: 'campaign'}
    | {payload: RpcType['categoryCreate']['payload']; type: 'category'}
    | {payload: RpcType['industryCreate']['payload']; type: 'industry'}
    | {payload: RpcType['mediaOwnerCreate']['payload']; type: 'mediaOwner'}
    | {payload: RpcType['mediaTypeCreate']['payload']; type: 'mediaType'}
    | {payload: RpcType['productCreate']['payload']; type: 'product'}
    | {payload: RpcType['publicationCreate']['payload']; type: 'publication'}
    | {payload: RpcType['publisherCreate']['payload']; type: 'publisher'}
    | {payload: RpcType['regionCreate']['payload']; type: 'region'};

export const entityCreate = createRequestHook({
    name: 'entityCreate',
    schema: getEntityItemSchema(),
    request: async (input: EntityCreateInput, meta) => {
        switch (input.type) {
            case 'adType':
                return {adType: await RpcRequest('adTypeCreate')(input.payload, meta)};
            case 'brand':
                return {brand: await RpcRequest('brandCreate')(input.payload, meta)};
            case 'campaign':
                return {campaign: await RpcRequest('campaignCreate')(input.payload, meta)};
            case 'category':
                return {category: await RpcRequest('categoryCreate')(input.payload, meta)};
            case 'industry':
                return {industry: await RpcRequest('industryCreate')(input.payload, meta)};
            case 'mediaOwner':
                return {mediaOwner: await RpcRequest('mediaOwnerCreate')(input.payload, meta)};
            case 'mediaType':
                return {mediaType: await RpcRequest('mediaTypeCreate')(input.payload, meta)};
            case 'product':
                return {product: await RpcRequest('productCreate')(input.payload, meta)};
            case 'publication':
                return {publication: await RpcRequest('publicationCreate')(input.payload, meta)};
            case 'publisher':
                return {publisher: await RpcRequest('publisherCreate')(input.payload, meta)};
            case 'region':
                return {region: await RpcRequest('regionCreate')(input.payload, meta)};
            default:
                throw new Error(`update was not handled`);
        }
    }
});

/** Bespoke endpoint used for creating a media owner, and then using the new media owner's ID to
 * create a new publisher. Used in the publisher inline form on a publication page */
export const createPublisherAndMediaOwner = createRequestHook({
    name: 'createPublisherAndMediaOwner',
    schema: getPublisherAndMediaOwnerSchema(),
    request: async (
        input: {
            publisher: Omit<RpcType['publisherCreate']['payload'], 'mediaOwner'>;
            mediaOwner: RpcType['mediaOwnerCreate']['payload'];
        },
        meta
    ) => {
        const mediaOwner = await RpcRequest('mediaOwnerCreate')(input.mediaOwner, meta);

        const publisher = await RpcRequest('publisherCreate')(
            {...input.publisher, mediaOwner: mediaOwner.id},
            meta
        );

        return {mediaOwner, publisher};
    }
});

type GetEntitiesByNameInput =
    | {payload: RpcType['adTypeGetNamesToEntities']['payload']; type: 'adType'}
    | {payload: RpcType['brandGetNamesToEntities']['payload']; type: 'brand'}
    | {payload: RpcType['campaignGetNamesToEntities']['payload']; type: 'campaign'}
    | {payload: RpcType['categoryGetNamesToEntities']['payload']; type: 'category'}
    | {payload: RpcType['industryGetNamesToEntities']['payload']; type: 'industry'}
    | {payload: RpcType['mediaTypeGetNamesToEntities']['payload']; type: 'mediaType'}
    | {payload: RpcType['productGetNamesToEntities']['payload']; type: 'product'}
    | {payload: RpcType['publicationGetNamesToEntities']['payload']; type: 'publication'}
    | {payload: RpcType['regionGetNamesToEntities']['payload']; type: 'region'};
export const getEntitiesByName = createRequestHook({
    name: 'getEntitiesByName',
    schema: getEntityListSchema(),
    request: async (input: GetEntitiesByNameInput, meta) => {
        switch (input.type) {
            case 'adType':
                return {adType: await RpcRequest('adTypeGetNamesToEntities')(input.payload, meta)};
            case 'brand':
                return {brand: await RpcRequest('brandGetNamesToEntities')(input.payload, meta)};
            case 'campaign':
                return {
                    campaign: await RpcRequest('campaignGetNamesToEntities')(input.payload, meta)
                };
            case 'category':
                return {
                    category: await RpcRequest('categoryGetNamesToEntities')(input.payload, meta)
                };
            case 'industry':
                return {
                    industry: await RpcRequest('industryGetNamesToEntities')(input.payload, meta)
                };
            case 'mediaType':
                return {
                    mediaType: await RpcRequest('mediaTypeGetNamesToEntities')(input.payload, meta)
                };
            case 'product':
                return {
                    product: await RpcRequest('productGetNamesToEntities')(input.payload, meta)
                };
            case 'publication':
                return {
                    publication: await RpcRequest('publicationGetNamesToEntities')(
                        input.payload,
                        meta
                    )
                };
            case 'region':
                return {region: await RpcRequest('regionGetNamesToEntities')(input.payload, meta)};
            default:
                throw new Error(`update was not handled`);
        }
    }
});

export const entityRulesActivation = createRequestHook({
    name: 'entityRulesActivation',
    schema: new ObjectSchema({}),
    request: async (input: RpcType['entityRuleActivateMany']['payload'], meta) => {
        return await RpcRequest('entityRuleActivateMany')(input, meta);
    }
});
