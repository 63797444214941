import Portal from './Portal';
import React, {useRef} from 'react';
import Styled from 'styled-components';
import {autoUpdate, useFloating, useFocus, useRole, FloatingArrow} from '@floating-ui/react';
import {offset, shift, flip, arrow, hide, useHover, useInteractions} from '@floating-ui/react';
import useStateWithDeps from '../util/useStateWithDeps';

type Props = {
    //
    // Reference node config
    /** Node which triggers the floater */
    children: any;
    cursor?: React.CSSProperties['cursor'];
    tooltipDisplay?: React.CSSProperties['display'];
    tooltipControlStyle?: React.CSSProperties;

    //
    // Floater config
    /** Floater content */
    content: React.ReactNode;
    /** Floater max width. */
    maxWidth?: string;
    placement?: 'top' | 'bottom' | 'left' | 'right';
    offset?: number;
    floaterStyle?: Record<string, any>;
    /** Whether to use absolute positioning to place the floater. Useful if you're already using CSS
     * transforms to alter the position of the floater. Defaults to true */
    useTransform?: boolean;
    /** Force the tooltip to always be shown */
    alwaysVisible?: boolean;
};

const Tooltip = Styled.div<{
    cursor?: React.CSSProperties['cursor'];
    display: React.CSSProperties['display'];
}>`
    min-width: 0;
    cursor: ${(p) => p.cursor};
    display : ${(p) => p.display};
`;

const Floater = Styled.div<{maxWidth?: string}>`${(p) => {
    const {background, foreground: copy} = p.theme.colors;
    const {maxWidth} = p;

    return `
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        pointer-events: none;
        border-radius: .25rem;
        background-color: ${copy};
        color: ${background};
        padding: .5rem .75rem;
        line-height: 1.4;
        font-size: .9em;
        z-index: ${p.theme.zIndices.Tooltip || 0};
        width: max-content;
        min-width: 5rem;
        max-width: ${maxWidth ?? '14rem'};
`;
}}`;

export default function TooltipComponent(props: Props) {
    const {children} = props;
    const {content} = props;
    const {placement = 'bottom'} = props;
    const {tooltipDisplay = 'block'} = props;
    const {useTransform = true} = props;
    const {floaterStyle, offset: tooltipOffset = 16} = props;

    const [open, setOpen] = useStateWithDeps(props.alwaysVisible || false, [props.alwaysVisible]);
    const arrowRef = useRef<SVGSVGElement>(null);
    const {refs, context, floatingStyles, middlewareData} = useFloating({
        transform: useTransform,
        open,
        placement,
        whileElementsMounted: autoUpdate,
        onOpenChange: setOpen,
        // order of middleware functions is important
        middleware: [
            offset(tooltipOffset),
            // keep floater in view if a horizontal scroll would make it offscreen
            shift(),
            // change floater placement if it would be offscreen when scrolling
            flip(),
            // should be towards the end of the array, after `shift`
            arrow({element: arrowRef}),
            // hide float if reference element isn't visible
            hide()
        ]
    });

    const hover = useHover(context);
    const focus = useFocus(context);
    const role = useRole(context, {role: 'tooltip'});

    const {getFloatingProps, getReferenceProps} = useInteractions([hover, focus, role]);

    const cursor = props.cursor ?? (content ? 'pointer' : 'default');

    return (
        <>
            <Tooltip
                cursor={cursor}
                display={tooltipDisplay}
                ref={refs.setReference}
                style={props.tooltipControlStyle}
                {...getReferenceProps()}
            >
                {children}
            </Tooltip>
            {open && content && (
                <Portal>
                    <Floater
                        style={{
                            ...floatingStyles,
                            ...floaterStyle,
                            visibility: middlewareData.hide?.referenceHidden ? 'hidden' : 'visible'
                        }}
                        ref={refs.setFloating}
                        {...getFloatingProps()}
                        maxWidth={props.maxWidth}
                    >
                        {content}
                        <FloatingArrow ref={arrowRef} context={context} />
                    </Floater>
                </Portal>
            )}
        </>
    );
}
