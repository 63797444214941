import Brand, {BrandInput} from '../../brand/data/Brand';

export type ProductInput = {
    id?: string;
    name: string;
    brand?: Brand | BrandInput | null;
    hasAccess?: boolean | null;
};

export default class Product {
    private data: ProductInput;
    type = 'product' as const;

    constructor(input: ProductInput) {
        this.data = input;
    }

    static unavailableLabel = 'Unavailable';

    get id() {
        return this.data.id || this.data.name;
    }

    /** Product name only */
    get name() {
        return this.data.name;
    }

    /** Product name (Brand name) */
    get label() {
        return `${this.name}${this.brand ? ` (${this.brand.label})` : ''}`;
    }

    get brand() {
        if (!this.data.brand) return;
        if (this.data.brand instanceof Brand) return this.data.brand;
        return new Brand(this.data.brand);
    }

    get hasAccess() {
        return this.data.hasAccess ?? null;
    }
}
