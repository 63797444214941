import Industry, {IndustryInput} from '../../industry/data/Industry';

export type BrandInput = {
    id?: string;
    name: string;
    industries?: Array<IndustryInput | Industry> | null;
    hasAccess?: boolean | null;
};

export default class Brand {
    private data: BrandInput;
    private baseUrl: string;
    private mediaUrl = process.env.MEDIA_URL ?? '';
    type = 'brand' as const;

    constructor(input: BrandInput) {
        this.data = input;
        this.baseUrl = `/brand/${input.id || input.name}/`;
    }

    static to(id: string) {
        return `/brand/${id}/`;
    }

    get id() {
        return this.data.id || this.data.name;
    }

    get label() {
        return this.data.name;
    }

    get logoUrl() {
        return `${this.mediaUrl}logo/${this.id}/logo.png`;
    }

    get to() {
        return `${this.baseUrl}creative`;
    }

    toMediaValue(params?: {startDate?: string; endDate?: string}) {
        let query = '';
        if (params) {
            const q = JSON.stringify({
                industry: 'All',
                'industry.lens': 'Historical',
                'industry.chart': 'Line',
                'mediaType.lens': 'Historical',
                'mediaType.chart': 'Stacked',
                'adType.lens': 'Historical',
                'adType.chart': 'Stacked',
                'region.lens': 'Historical',
                'region.chart': 'Stacked',
                'category.lens': 'Historical',
                'category.chart': 'Stacked',
                dateRangeFilter: {
                    absolute: {startDate: params.startDate, endDate: params.endDate}
                }
            });
            query = `?${new URLSearchParams({q})}`;
        }

        return `${this.baseUrl}value${query}`;
    }

    get trackingType() {
        return {type: 'brand', name: this.label};
    }

    get color() {
        return undefined;
    }

    get industryList() {
        if (!this.data.industries) return [];
        return this.data.industries.map((ii) => (ii instanceof Industry ? ii : new Industry(ii)));
    }

    get hasAccess() {
        return this.data.hasAccess ?? null;
    }
}
